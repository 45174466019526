import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SocialApps from './social/index';
import Evaluation from './evaluations/index';
import BooksGrid from './books/BookGrid';
import VideoGrid from './books/VideoGrid';

import EvaluationsList from './series/EvaluationsList';
import UserAuth from "./userAuth";
import BookContent from './books/BookContent';

const App = ({ match }) => (
	<div className="gx-main-content-wrapper">
		<Switch>
		<Route path={`${match.url}series/:seriesId/evaluations/:evId`} component={Evaluation} />
			<Route path={`${match.url}series/:urlId/books`} component={BooksGrid} />
			<Route path={`${match.url}series/:urlId/videos`} component={VideoGrid} />
			<Route path={`${match.url}series/:urlId/book/:bookId`} component={BookContent} />

			<Route path={`${match.url}series/:urlId/evaluations`} component={EvaluationsList} /> 
			<Route path={`${match.url}`} component={SocialApps} />
			<Route path={`${match.url}user-auth`} component={UserAuth}/>

		</Switch>
	</div>
);

export default App;
