import React from "react";
import { Button, Progress, Divider, Link, Modal } from "antd";
import VideoEvaluation from "../../routes/videoevaluations";

/*const EvaluationPorcentage = ({seriesId, evid, product, grid, solvedEv}) => {
    const {image, total, title} = product;
    const {attempt_number, porcentage, grade, correct_answers, status} = solvedEv[0]? solvedEv[0].data(): "";
*/
class EvaluationPorcentage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attempt: 1,
            width: 800,
            grade: 0,
            mobile: false,
            status: "Por iniciar",
            porcentage: 0,
            showVideo: false,
            selectedEvaluation: ""

        }
        this.updateWindowDimensions = this
            .updateWindowDimensions
            .bind(this);
            this.updateData = this
            .updateData
            .bind(this);
        this.showModal = this
            .showModal
            .bind(this);
        this.hideModal = this
            .hideModal
            .bind(this);
        this.onModalClose = this
            .onModalClose
            .bind(this);

    }
    showModal = () => {
        this.setState({ showVideo: true });
    };
    hideModal = () => {
        this.setState({ showVideo: false });
        this.onModalClose()
    };
    onModalClose = () => {
        this.setState({ showVideo: false });
        setTimeout(() => {
            window.location.reload(false);
            //this.setState({attempt})
        }, 600);
    };
    updateWindowDimensions() {
        this.setState({
            width: (window.innerWidth * 0.8) / 1.77,
            height: window.innerHeight
        });
    }
  

    updateData(param){
        //console.log(param)
        if(param.porcentage != undefined){
            //console.log("UDPDATING %")
            this.setState({porcentage: param.porcentage})
        }
        if(param.status !=  undefined){
            this.setState({status: param.status})
        }
        if(param.attempt !=  undefined && param.attempt>0){
            this.setState({attempt: param.attempt})
        }
        if(param.grade !=  undefined){
            this.setState({grade: param.grade})
        }
    }

    componentDidMount() {
        //console.log("WINDOW")
        //console.log(window.innerHeight)
        if (window.innerWidth < 768) {
            this.setState({
                mobile: true
            });
        }

        if (this.props.solvedEv != undefined) {
            if (this.props.solvedEv[0] != undefined) {
                this.setState({
                    status: this.props.solvedEv[0].data().status,
                    attempt: this.props.solvedEv[0].data().attempt_number,
                    porcentage: this.props.solvedEv[0].data().porcentage,
                    grade: this.props.solvedEv[0].data().grade
                })
            }
        }
    }
    render() {
        return (
            <div
                className={` ${this.state.mobile
                    ? 'gx-product-vertical'
                    : 'gx-product-horizontal'}`}>
                <div className="gx-product-image">
                    <div className="gx-grid-thumb-equal shorten">
                        <span className="gx-link gx-grid-thumb-cover ">
                            <img className="round-corner-image-border" src={this.props.product.image} />
                        </span>
                    </div>
                </div>

                <div className="gx-product-body">
                    <h3 className="gx-product-title">{this.props.product.title}
                    </h3>
                    <h3 className="green-text">Intento: {this.state.attempt} de 3</h3>
                    <Progress percent={this.state.porcentage} status="active" />
                    <p className="dark-blue-text" >Porcentaje de avance</p>
                    <Progress percent={this.state.grade} />
                    <p className="dark-blue-text">Porcentaje de aciertos</p>
                </div>
                
                <Modal
                    keyboard={false}
                    closable={false}
                    centered
                    width={this.props.width}
                    footer={null}
                    visible={this.state.showVideo}>
                    <p>
                        {this.props.product.title}
                    </p>
                    <Divider />
                    <VideoEvaluation
                    onModalClosing={()=>this.hideModal()}
                        currentStatus={this.state.status}
                        currentAttempt={this.state.attempt}
                        updateData={(param) => this.updateData(param)}
                        series={this.props.series}
                        seriesId={this.props.seriesId}
                        evId={this.props.evid} />
                </Modal>
                <div className="gx-product-footer">
                    <Button type="dashed">{(this.state.status)}</Button>

                    {(this.props.product.video_evaluation === true)
                        ? <Button
                            type="primary"
                            onClick={() => {
                                this.setState({ showVideo: true });
                            }}>Ver el contenido.</Button>
                        : null}

                    {((this.props.product.video_evaluation === false && this.state.status != null && (this.state.status == "En curso" || this.state.status == "Por iniciar"))
                        ? <Button
                            type="primary"
                            href={"/series/" + this.props.seriesId + "/evaluations/" + this.props.evid}>Ver las preguntas.</Button>
                        : null)}
                    {((this.state.status != null && this.state.status == "Reprobado" && this.state.attempt != '3')
                        ? <Button
                            type="primary"
                            href={"/series/" + this.props.seriesId + "/evaluations/" + this.props.evid}>Volver a intentar.</Button>
                        : null)}

                </div>
               
            </div>
            
        )
        //};
    }
}
export default EvaluationPorcentage;
