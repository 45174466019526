import React from 'react';
import {
	Button,
	Checkbox,
	Form,
	Icon,
	Input,
	message,
	Dropdown,
	DatePicker,
	Menu,
	Upload,
	Select,
	Col,
	Layout,
	Row
} from 'antd';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { auth, database } from '../firebase/firebase';
import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import Moment from "moment";
import { version } from "util/config";

import { connect } from 'react-redux';
import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignUp,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';


const FormItem = Form.Item;
const Option = Select.Option;
const codes = [
"PISALPRA",
"EQUIVAC",
"LAMOBRIGAN",
"PISALPRA1",
"PISALPRA2",
"PISALPRA3",
"PISALPRA4",
"PISALPRA5",
"PISALPRA6",
"PISALPRA7",
"PISALPRA8",
"PISALPRA9",
"PISALPRA10",
"PISALPRA11",
"PISALPRA12",
"PISALPRA13",
"PISALPRA14",
"PISALPRA15",
"PISALPRA16",
"PISALPRA17",
"PISALPRA18",
"PISALPRA19",
"PISALPRA20",
"EQUIVAC1",
"EQUIVAC2",
"EQUIVAC3",
"EQUIVAC4",
"EQUIVAC5",
"EQUIVAC6",
"EQUIVAC7",
"EQUIVAC8",
"EQUIVAC9",
"EQUIVAC10",
"EQUIVAC11",
"EQUIVAC12",
"EQUIVAC13",
"EQUIVAC14",
"EQUIVAC15",
"EQUIVAC16",
"EQUIVAC17",
"EQUIVAC18",
"EQUIVAC19",
"EQUIVAC20",
"LAMOBRIGAN1",
"LAMOBRIGAN2",
"LAMOBRIGAN3",
"LAMOBRIGAN4",
"LAMOBRIGAN5",
"LAMOBRIGAN6",
"LAMOBRIGAN7",
"LAMOBRIGAN8",
"LAMOBRIGAN9",
"LAMOBRIGAN10",
"LAMOBRIGAN11",
"LAMOBRIGAN12",
"LAMOBRIGAN13",
"LAMOBRIGAN14",
"LAMOBRIGAN15",
"LAMOBRIGAN16",
"LAMOBRIGAN17",
"LAMOBRIGAN18",
"LAMOBRIGAN19",
"LAMOBRIGAN20",
"PISA1",
"PISA2",
"PISA3",
"PISA4",
"PISA5",
"PISA6",
"PISA7",
"PISA8",
"PISA9",
"PISA10",
"PISA11",
"PISA12",
"PISA13",
"PISA14",
"PISA15",
"PISA16",
"PISA17",
"PISA18",
"PISA19",
"PISA20",
"PISA21",
"PISA22",
"PISA23",
"PISA24",
"PISA25",
"PISA26",
"PISA27",
"PISA28",
"PISA29",
"PISA30",
"PISA31",
"PISA32",
"PISA33",
"PISA34",
"PISA35",
"PISA36",
"PISA37",
"PISA38",
"PISA39",
"PISA40",
"PISA41",
"PISA42",
"PISA43",
"PISA44",
"PISA45",
"PISA46",
"PISA47",
"PISA48",
"PISA49",
"PISA50",
"PISA51",
"PISA52",
"PISA53",
"PISA54",
"PISA55",
"PISA56",
"PISA57",
"PISA58",
"PISA59",
"PISA60",
"PISA61",
"PISA62",
"PISA63",
"PISA64",
"PISA65",
"PISA66",
"PISA67",
"PISA68",
"PISA69",
"PISA70",
"PISA71",
"PISA72",
"PISA73",
"PISA74",
"PISA75",
"PISA76",
"PISA77",
"PISA78",
"PISA79",
"PISA80",
"PISA81",
"PISA82",
"PISA83",
"PISA84",
"PISA85",
"PISA86",
"PISA87",
"PISA88",
"PISA89",
"PISA90",
"PISA91",
"PISA92",
"PISA93",
"PISA94",
"PISA95",
"PISA96",
"PISA97",
"PISA98",
"PISA99",
"PISA100",
"DOCTUALIZA1",
"DOCTUALIZA2",
"DOCTUALIZA3",
"DOCTUALIZA4",
"DOCTUALIZA5",
"DOCTUALIZA6",
"DOCTUALIZA7",
"DOCTUALIZA8",
"DOCTUALIZA9",
"DOCTUALIZA10",
"DOCTUALIZA11",
"DOCTUALIZA12",
"DOCTUALIZA13",
"DOCTUALIZA14",
"DOCTUALIZA15",
"DOCTUALIZA16",
"DOCTUALIZA17",
"DOCTUALIZA18",
"DOCTUALIZA19",
"DOCTUALIZA20",
"DOCTUALIZA21",
"DOCTUALIZA22",
"DOCTUALIZA23",
"DOCTUALIZA24",
"DOCTUALIZA25",
"DOCTUALIZA26",
"DOCTUALIZA27",
"DOCTUALIZA28",
"DOCTUALIZA29",
"DOCTUALIZA30",
"DOCTUALIZA31",
"DOCTUALIZA32",
"DOCTUALIZA33",
"DOCTUALIZA34",
"DOCTUALIZA35",
"DOCTUALIZA36",
"DOCTUALIZA37",
"DOCTUALIZA38",
"DOCTUALIZA39",
"DOCTUALIZA40",
"DOCTUALIZA41",
"DOCTUALIZA42",
"DOCTUALIZA43",
"DOCTUALIZA44",
"DOCTUALIZA45",
"DOCTUALIZA46",
"DOCTUALIZA47",
"DOCTUALIZA48",
"DOCTUALIZA49",
"DOCTUALIZA50",
"DOCTUALIZA51",
"DOCTUALIZA52",
"DOCTUALIZA53",
"DOCTUALIZA54",
"DOCTUALIZA55",
"DOCTUALIZA56",
"DOCTUALIZA57",
"DOCTUALIZA58",
"DOCTUALIZA59",
"DOCTUALIZA60",
"DOCTUALIZA61",
"DOCTUALIZA62",
"DOCTUALIZA63",
"DOCTUALIZA64",
"DOCTUALIZA65",
"DOCTUALIZA66",
"DOCTUALIZA67",
"DOCTUALIZA68",
"DOCTUALIZA69",
"DOCTUALIZA70",
"DOCTUALIZA71",
"DOCTUALIZA72",
"DOCTUALIZA73",
"DOCTUALIZA74",
"DOCTUALIZA75",
"DOCTUALIZA76",
"DOCTUALIZA77",
"DOCTUALIZA78",
"DOCTUALIZA79",
"DOCTUALIZA80",
"DOCTUALIZA81",
"DOCTUALIZA82",
"DOCTUALIZA83",
"DOCTUALIZA84",
"DOCTUALIZA85",
"DOCTUALIZA86",
"DOCTUALIZA87",
"DOCTUALIZA88",
"DOCTUALIZA89",
"DOCTUALIZA90",
"DOCTUALIZA91",
"DOCTUALIZA92",
"DOCTUALIZA93",
"DOCTUALIZA94",
"DOCTUALIZA95",
"DOCTUALIZA96",
"DOCTUALIZA97",
"DOCTUALIZA98",
"DOCTUALIZA99",
"DOCTUALIZA100",

]

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJPG = file.type === 'image/jpeg';
	if (!isJPG) {
		message.error('You can only upload JPG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJPG && isLt2M;
}

class SignUp extends React.Component {
	handleChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					imageUrl,
					loading: false
				})
			);
		}
	};
	handleInputSessionChange(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ onSession: e });
	}
	handleInputChange(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ isGoing: e });
	}
	handleSessionChange(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ onSession: e });
	}
	handleCodeClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ code: e });


	}
	handleStateClick(e) {
		this.setState({ state: e });
	}
	handleSpecialtyClick(e) {
		this.setState({ speciality: e });
	}
	handleSexClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ sex: e });
	}
	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/profile" />;
		}
	};

	handleSignIn = (err, values) => {
		//console.log('data');
		//console.log(err);
		//console.log(values);
		if (!err) {
			var _this = this;


			//console.log('CALLING IF');
			if (values.avatar) {
				values.avatar = 'https://firebasestorage.googleapis.com/v0/b/curso-demencia.appspot.com/o/avatar-doctor.jpg?alt=media';
			}
			if (values.other_speciality === undefined) {
				values.other_speciality = '';
			}
			if (!values.other_speciality) {
				values.other_speciality = '';
			}

			if (values.city === undefined) {
				values.city = ""
			}
			if (values.phone === undefined) {
				values.phone = ""
			}
			if (values.privacy !== true) {
				message.error('Debes aceptar el aviso de privacidad para continuar.');

				return;
			}
			if (values.onSession !== true) {
				message.error('Debes aceptar el uso de cookies para continuar.');

				return;
			}
		
			//if(this.state.isGoing == false){
			//if(values.age)
			//this.setState({ sex: values.sex});
			this.setState({ code: values.code });
			this.setState({ sex: values.sex });

			if (codes.indexOf(values.code) != -1) {
				this.setState({ state: values.state });
				//console.log('CALLING LOADER');
				//this.props.showAuthLoader();

				this.setState({ values: values });
			
				values.email = values.email.trim().toLowerCase()

				//console.log(values);
				database.collection('users').where('email', '==', values.email).get().then(function (snapshot) {
					if (snapshot.docs.length == 0) {
						//console.log(Moment().format("DD/MM/YYYY'T'hh:mm:ss"))
						if (values.password.length >= 6) {
							values.registration_date = Moment().format("DD/MM/YYYY'T'hh:mm:ss")

							database
								.collection('users')
								.add(values)
								.then(function (docRef) {
									//console.log('WRITING USER DATA DONE');
									_this.props.userSignUp(values);
								})
								.catch(function (error) {
									//console.error('Error adding document: ', error);
								});
						} else {
							//console.log('Short Password');
							message.error('La contraseña debe de tener al menos 6 caracteres');

							//_this.props.alertMessage = 'Este usuario ya esta registrado';
						}
					} else {
						message.error('Este usuario ya esta registrado');

					}
				});
			} else {
				message.error('Código de acceso inválido');
			}


		} else {
			//console.log('ERROR IS NOT NULL');
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			this.handleSignIn(err, values);
			//console.log(moment(values.birthdate).format('MM/DD/YYYY'));
		});
	};

	constructor() {
		super();
		this.state = {
			loading: false,
			state: 'Aguascalientes',
			sex: 'Masculino',
			code: 'doctualiza1',
			onSession:true,
			redirect: false,
			signing: false,
			values: {}
			//email: 'demo@example.com',
			//password: 'demo#123'
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		this.handleSignIn = this.handleSignIn.bind(this);
		this.handleCodeClick = this.handleCodeClick.bind(this);
		this.handleSpecialtyClick = this.handleSpecialtyClick.bind(this);
		this.handleSexClick = this.handleSexClick.bind(this);

		this.handleStateClick = this.handleStateClick.bind(this);
		this.handleInputSessionChange = this.handleInputSessionChange.bind(this);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSessionChange = this.handleSessionChange.bind(this);

	}

	componentDidMount() {
		this.setState({ state: 'Aguascalientes', code: "ZATHELO1", isGoing: true, onSession: true});
	}

	componentDidUpdate() {
		//console.log("V1.233")
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}

		//console.log(this.props);
		//console.log(this.state);
		if (!this.state.signing) {
			if (this.props.authUser !== null) {
				auth.onAuthStateChanged((user) => {
					if (user) {
						//console.log('AUTH USER IS ALL RIGHT');
						//this.props.history.push('/');
						//console.log('redirecting...');
						this.setRedirect();
					} else {
						if (this.state.values.email && this.state.values.password) {
							var _this = this;
							this.setState({ signing: true });
							//console.log(this.props.authUser);
							//console.log('AUTH USER IS NOT NUL BUT FIREBASE IS; SIGNING IN....');
							auth
								.signInWithEmailAndPassword(this.state.values.email, this.state.values.password)
								.then((authUser) => {
									this.props.history.push('//');
								})
								.catch((error) => error);
						} else {
							//console.log('EMPTY VALUES');
						}
					}
				});
			} else {
				//console.log('AUTH USER IS NULL');
			}
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		const imageUrl = this.state.imageUrl;
		return (
			<div className="gx-app-login-wrap" >
				<div className="gx-app-login-wrap" id="login-cont">

					<div className="gx-app-login-container">

						<div className="gx-app-login-main-content">

							{this.renderRedirect()}

							<div className="gx-app-login-content gx-app-signup-content">
								<Row><Col span="6"></Col><Col span="12">
									<img alt="example" src={require('assets/images/logo_login.png')} /> 	<br></br>	<br></br>	<br></br>
								</Col><Col span="3"></Col>
									<p id="login-message"><span className="dark-blue-text heavy-text">PiSA Farmacéutica</span> le da la bienvenida a nuestra plataforma de Educación Médica Continua.<br></br><span className="heavy-text green-text">Le invitamos a realizar tu registro</span></p>
								</Row>
								{/* <Row>
								<Col span="8" />
								<Col span="8">
									{' '}
								 <img alt="example" src={require('assets/images/asset_3.png')} /> 
								</Col>
								<Col span="8" />
							</Row>
							<br /> */}
								<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
									<Row>
										<Col xl={12} lg={12} md={24} sm={24} xs={24}>
											<FormItem>
												{getFieldDecorator('name', {
													rules: [{ required: true, message: 'Por favor escribe tu nombre' }]
												})(<Input placeholder="Nombre(s)" className="big-input" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('paternal_lastname', {
													rules: [{ required: true, message: 'Ingrese su apellido paterno' }]
												})(<Input placeholder="Apellido Paterno" className="big-input" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('maternal_lastname', {
													rules: [{ required: true, message: 'Ingrese su apellido materno' }]
												})(<Input placeholder="Apellido Materno" className="big-input" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('sex', {
													rules: [{ required: true, message: 'Por favor seleccione su sexo' }]
												})(
													<Select
														name="sex"
														onChange={this.handleSexClick}
														value={this.state.sex}
														placeholder="Sexo"
													>
														<Option value="Masculino">Masculino</Option>
														<Option value="Femenino">Femenino</Option>
													</Select>
												)}
											</FormItem>




											<FormItem>
												{getFieldDecorator('state', {
													rules: [{ required: true, message: 'Por favor ingrese su estado' }]
												})(
													<Select
														name="state"
														onChange={this.handleStateClick}
														value={this.state.state}
														placeholder="Estado"
													>
														<Option value="Aguascalientes">Aguascalientes</Option>
														<Option value="Baja California">Baja California</Option>
														<Option value="Baja California Sur">Baja California Sur</Option>
														<Option value="Campeche">Campeche</Option>
														<Option value="Chiapas">Chiapas</Option>
														<Option value="Chihuahua">Chihuahua</Option>
														<Option value="Ciudad de México">Ciudad de México</Option>
														<Option value="Coahuila">Coahuila</Option>
														<Option value="Colima">Colima</Option>
														<Option value="Durango">Durango</Option>
														<Option value="Guanajuato">Guanajuato</Option>
														<Option value="Guerrero">Guerrero</Option>
														<Option value="Hidalgo">Hidalgo</Option>
														<Option value="Jalisco">Jalisco</Option>
														<Option value="Estado de México">Estado de México</Option>
														<Option value="Michoacán">Michoacán</Option>
														<Option value="Morelos">Morelos</Option>
														<Option value="Nayarit">Nayarit</Option>
														<Option value="Nuevo León">Nuevo León</Option>
														<Option value="Oaxaca">Oaxaca</Option>
														<Option value="Puebla">Puebla</Option>
														<Option value="Querétaro">Querétaro</Option>
														<Option value="Quintana Roo">Quintana Roo</Option>
														<Option value="San Luis Potosí">San Luis Potosí</Option>
														<Option value="Sinaloa">Sinaloa</Option>
														<Option value="Sonora">Sonora</Option>
														<Option value="Tabasco">Tabasco</Option>
														<Option value="Tamaulipas">Tamaulipas</Option>
														<Option value="Tlaxcala">Tlaxcala</Option>
														<Option value="Veracruz">Veracruz</Option>
														<Option value="Yucatán">Yucatán</Option>
														<Option value="Zacatecas">Zacatecas</Option>


													</Select>
												)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('city', {
													rules: [{ required: true, message: 'Ingrese su ciudad' }]
												})(<Input placeholder="Ciudad" className="big-input" />)}
											</FormItem>
											{/* <FormItem>
											{getFieldDecorator('city', {
												rules: [ { required: true, message: 'Ingrese su ciudad' } ]
											})(<Input placeholder="Ciudad" />)}
										</FormItem> */}



										</Col>
										<Col xl={12} lg={12} md={24} sm={24} xs={24}>


											<FormItem>
												{getFieldDecorator('license', {
													rules: [{ required: true, message: 'Ingrese su cédula profesional' }]
												})(<Input placeholder="Cédula Profesional" className="big-input" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('speciality', {
													rules: [{ required: true, message: 'Por favor seleccione su especialidad' }]
												})(
													<Select
														name="speciality"
														onChange={this.handleSpecialtyClick}
														value={this.state.speciality}
														placeholder="Especialidad"
													>

<Option value="Administración en salud">Administración en salud</Option>
<Option value="Alergología">Alergología</Option>
<Option value="Algología">Algología</Option>
<Option value="Análisis clínico">Análisis clínico</Option>
<Option value="Anatomía patológica">Anatomía patológica</Option>
<Option value="Anestesiología">Anestesiología</Option>
<Option value="Angiología">Angiología</Option>
<Option value="Auditoría médica">Auditoría médica</Option>
<Option value="Bioquímica clínica">Bioquímica clínica</Option>
<Option value="Cardiología">Cardiología</Option>
<Option value="Cirugía cardíaca">Cirugía cardíaca</Option>
<Option value="Cirugía craneofacial">Cirugía craneofacial</Option>
<Option value="Cirugía general">Cirugía general</Option>
<Option value="Cirugía oral y maxilofacial">Cirugía oral y maxilofacial</Option>
<Option value="Cirugía ortopédica">Cirugía ortopédica</Option>
<Option value="Cirugía pediátrica">Cirugía pediátrica</Option>
<Option value="Cirugía plástica">Cirugía plástica</Option>
<Option value="Cirugía torácica">Cirugía torácica</Option>
<Option value="Cirugía vascular">Cirugía vascular</Option>
<Option value="Coloproctología">Coloproctología</Option>
<Option value="Dermatología">Dermatología</Option>
<Option value="Embriología">Embriología</Option>
<Option value="Endocrinología">Endocrinología</Option>
<Option value="Enfermería">Enfermería</Option>
<Option value="Epidemiología">Epidemiología</Option>
<Option value="Estomatología">Estomatología</Option>
<Option value="Farmacología">Farmacología</Option>
<Option value="Farmacología Clínica">Farmacología Clínica</Option>
<Option value="Foniatría">Foniatría</Option>
<Option value="Gastroenterología">Gastroenterología</Option>
<Option value="Genética">Genética</Option>
<Option value="Genética médica">Genética médica</Option>
<Option value="Geriatría">Geriatría</Option>
<Option value="Ginecología y obstetricia o tocología">Ginecología y obstetricia o tocología</Option>
<Option value="Hematología">Hematología</Option>
<Option value="Hepatología">Hepatología</Option>
<Option value="Infectología">Infectología</Option>
<Option value="Inmunología">Inmunología</Option>
<Option value="Medicina aeroespacial">Medicina aeroespacial</Option>
<Option value="Medicina de emergencia">Medicina de emergencia</Option>
<Option value="Medicina del deporte">Medicina del deporte</Option>
<Option value="Medicina del trabajo">Medicina del trabajo</Option>
<Option value="Medicina física y rehabilitación">Medicina física y rehabilitación</Option>
<Option value="Medicina forense">Medicina forense</Option>
<Option value="Medicina general">Medicina general</Option>
<Option value="Medicina intensiva">Medicina intensiva</Option>
<Option value="Medicina interna">Medicina interna</Option>
<Option value="Medicina nuclear">Medicina nuclear</Option>
<Option value="Medicina paliativa">Medicina paliativa</Option>
<Option value="Medicina preventiva y salud pública">Medicina preventiva y salud pública</Option>
<Option value="Microbiología y parasitología">Microbiología y parasitología</Option>
<Option value="Nefrología">Nefrología</Option>
<Option value="Neumología">Neumología</Option>
<Option value="Neurocirugía">Neurocirugía</Option>
<Option value="Neurofisiología clínica">Neurofisiología clínica</Option>
<Option value="Neurología">Neurología</Option>
<Option value="Nutriología">Nutriología</Option>
<Option value="Odontología">Odontología</Option>
<Option value="Oftalmología">Oftalmología</Option>
<Option value="Otorrinolaringología">Otorrinolaringología</Option>
<Option value="Pediatría">Pediatría</Option>
<Option value="Psiquiatría">Psiquiatría</Option>
<Option value="Radiología">Radiología</Option>
<Option value="Reumatología">Reumatología</Option>
<Option value="Salud pública">Salud pública</Option>
<Option value="Toxicología">Toxicología</Option>
<Option value="Traumatología y Ortopedia">Traumatología y Ortopedia</Option>
<Option value="Urología">Urología</Option>

													</Select>
												)}
											</FormItem>




											<FormItem>
												{getFieldDecorator('code', {
													rules: [{ required: true, message: 'Código de acceso' }]
												})(<Input placeholder="Ingrese su Clave de acceso" className="big-input" />)}
											</FormItem>




											{/* <span>Fotografía de perfil</span> */}

											{/* <FormItem>
									{getFieldDecorator('avatar', {
										rules: [
											{
												required: false,
												message: 'Please upload your avatar!'
											}
										]
									})(
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											//action="//jsonplaceholder.typicode.com/posts/"
											beforeUpload={this.beforeUpload}
											onChange={this.handleChange}
										>
											{imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
										</Upload>
									)}
								</FormItem> */}
											<FormItem>
												{getFieldDecorator('email', {
													rules: [
														{
															required: true,
															type: 'email',
															message: 'The input is not valid E-mail!'
														}
													]
												})(<Input placeholder="Email" className="big-input" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('password', {
													rules: [{ required: true, message: 'Por favor escribe tu contraseña' }]
												})(<Input type="password" placeholder="Password" className="big-input" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('privacy', {
													rules: [{ required: true, message: 'Debes aceptar los términos y condiciones' }],
													valuePropName: 'checked',
													initialValue: true
												})(
													<Input
														className="big-input custom-checkbox"

														name="isGoing"
														type="checkbox"
														checked={this.state.isGoing}
														onChange={this.handleInputChange} ></Input>

												)}

												<p className="checkboxtext">
													Al registrarme acepto el <a className="gx-login-form-forgot" href=" https://www.doctualiza.com.mx/aviso" target="_blank">	Aviso de privacidad</a>


												</p>
											</FormItem>
											<FormItem>
												{getFieldDecorator('onSession', {
													rules: [{ required: true, message: 'para mantener la sesión abierta' }],
													valuePropName: 'checked',
													initialValue: true
												})(
													<Input
														name="onSession"
														type="checkbox"
														className="big-input custom-checkbox"
														checked={this.state.onSession}
														onChange={this.handleInputSessionChange} ></Input>

												)}

												<p className="checkboxtext">
													Al hacer click en REGISTRARME acepto el uso de cookies
												</p>
											</FormItem>
											

										</Col>
									</Row>


									<FormItem>
										<Button
											id="sesion"
											type="primary"
											className="gx-mb-0"
											htmlType="submit"
											onClick={this.handleSubmit}
										>
											<IntlMessages id="app.userAuth.signUp" />
										</Button>

										<Link to="/singin" className="gx-login-form-forgot">
											<IntlMessages id="app.userAuth.or" /> <IntlMessages id="app.userAuth.signIn" />
										</Link>
									</FormItem>


									<p id="register-disclamer">* Si usted aún no cuenta con su código de acceso, solicítelo a su representante PISA</p>
									<br></br>								<br></br>
									<br></br>
									<br></br>
									<Row className='bottom-links-login links-register'>
										<div className='bottom-links-center'>
											<a className="gx-login-bottom-link " href="https://www.doctualiza.com.mx/soporte" target="_blank">Soporte</a>
											<span>&nbsp;&nbsp;&nbsp;</span>
											<a className="gx-login-bottom-link" href="">·</a>
											<span>&nbsp;&nbsp;&nbsp;</span>
											<a className="gx-login-bottom-link" href="https://www.doctualiza.com.mx/formulariocontacto" target="_blank">Enviar correo electrónico</a>
											<span>&nbsp;&nbsp;&nbsp;</span>
											<a className="gx-login-bottom-link" href="">·</a>
											<span>&nbsp;&nbsp;&nbsp;</span>
											<a className="gx-login-bottom-link " href="https://www.doctualiza.com.mx/aviso" target="_blank">Aviso de Privacidad</a>

										</div>
									</Row>
								</Form>
													
							</div>
							<br></br>								<br></br>
									<br></br>
									<br></br>	<br></br>								
									<br></br>
								
							<span id="version-code-login">
								{version}</span>
							{loader ? (
								<div className="gx-loader-view">
									<CircularProgress />
								</div>
							) : null}
							{showMessage && message.error(alertMessage)}
						</div>

					</div>

				</div>
				<div className='corner-logo'><img id="pisa-logo" alt="example" src={require('assets/images/pisa_logo.png')} /> </div>
				<div class="vl"></div>
				<hr class="hl"></hr>
			</div>
		);
	}
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignUp,
	hideMessage,
	userSignIn,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedSignUpForm);
