import React from "react";
import {Button} from "antd";
import StarRatingComponent from "react-star-rating-component";

import IntlMessages from "util/IntlMessages";

function ProductItem ({click, product, grid, porcentage, id, completed, showinfo, series}) {
  //console.log(product)
  const { cover, book_file, link, book_link, file, video_file, title, content} = product;
  return (
    <div className={` ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}>
      <div className="gx-product-image">
        <div className="gx-grid-thumb-equal">
          <span className="gx-link gx-grid-thumb-cover">
            <img className="round-corner-image-border" alt="Remy Sharp" src={cover}/>
          </span>
        </div>
      </div>

      <div className="gx-product-body">
        <h3 className="gx-product-title">{title}
        </h3>
        {(showinfo)?
        <span>
        {(completed)? <div>  <div className="icon icon-check-circle-o">&nbsp;Visualización  completada</div></div> : <div>{porcentage}% <span>Completada</span></div> }
        </span>:null}
      </div>

      
        {(book_file && book_file != "")?
        <div className="gx-product-footer">
          <Button href={book_file} type="primary" target="_blank">Ver contenido</Button></div>:null}
          {(book_link && book_link != "")?
           <div className="gx-product-footer">
          <Button href={book_link} type="primary" target="_blank">Ver contenido</Button></div>:null}
      
      {((file && file != "") ||(link && link != "") || (content && content!=""))?<div className="gx-product-footer">

      {(file && file != "") ?
          <Button href={file} type="primary" target="_blank">Ver video</Button> : null}
          {(link && link != "") ?
          <Button type="primary" onClick={(event) => click(event, product, id)}>Ver video.</Button> : null}
             {(content && content != "") ?
          <Button href={"https://d.doctualiza.com.mx/series/"+series+"/book/"+id} type="primary">Ver contenido.</Button> : null}
    </div>:null}
    </div>
  )
};

export default ProductItem;

