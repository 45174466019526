import React, { Component } from 'react';
import {
    Col,
    Steps,
    Row,
    Button,
    Card,
    Modal,
    Popover,
    Radio,
    Slider,
    Icon
} from 'antd';

import ReactPlayer from 'react-player'


import Moment from 'moment';
import localization from 'moment/locale/es'

import SweetAlert from 'react-bootstrap-sweetalert';

import { auth, database, functions } from '../../firebase/firebase';
import { Redirect } from 'react-router-dom';
import VideoQuestion from './VideoQuestion';
import { fail } from 'assert';
const RadioGroup = Radio.Group;
const { Step } = Steps;

class VideoEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            seriesId: -1,
            evId: -1,
            serie: {},
            path: '',
            evaluation: {},
            currentSolvedEvaluation: {},
            solvedQuestions: [],
            uSolvedQuestions: [],
            success: false,
            maxTries: false,
            approved: false,
            denied: false,
            certificateWinner: false,
            failure: false,
            disabledButtons: false,
            gradeMessage: '',
            solvedQuestionsLoaded: false,
            solvedQuestionsPath: '',
            solvedEvPath: '',
            questions: [],
            questionsBySecond: {},
            danger: false,
            loading: false,
            grade: '',
            graded: false,
            gradedButton: 'Calificar',
            redirect: false,
            redirectHome: false,
            nextLimit: -1,
            modalText: 'Content of the modal',
            visible: false,
            confirmLoading: false,
            playing: true,
            uploadCounter: 0,
            watchTime: 0,
            porcentage: 0,
            myVideos: [],
            marks: {},
            currentVideoId: "",
            currentVideo: {},
            currentVideoTime: 0,
            watchedVideoDoc: {},
            currentVideoDuration: 0,
            gradeButtonVisible: false,
            popOverVisibility: false,
            readyToGrade: false,
            lastSecond: 0
        };

        this.showModal = this
            .showModal
            .bind(this);
        this.handleOk = this
            .handleOk
            .bind(this);
        this.getCertificateReady = this
            .getCertificateReady
            .bind(this);
        this.checkIfCertificateOrMessage = this
            .checkIfCertificateOrMessage
            .bind(this);

        this.handleCancel = this
            .handleCancel
            .bind(this);
        this.setRedirect = this
            .setRedirect
            .bind(this);
        this.renderRedirect = this
            .renderRedirect
            .bind(this);
        this.onAddAnswer = this
            .onAddAnswer
            .bind(this);
        this.onUpdateAnswer = this
            .onUpdateAnswer
            .bind(this);
        this.gradeEvaluation = this
            .gradeEvaluation
            .bind(this);
        this.getQuestionsInformation = this
            .getQuestionsInformation
            .bind(this);
        this.getEvaluationInformation = this
            .getEvaluationInformation
            .bind(this);
        this.getQuestionOptions = this
            .getQuestionOptions
            .bind(this);

        this.handleProgress = this
            .handleProgress
            .bind(this);
        this.handleDuration = this
            .handleDuration
            .bind(this);
        this.handleEnded = this
            .handleEnded
            .bind(this);
        this.hideAllQuestionModals = this
            .hideAllQuestionModals
            .bind(this);
        this.closeAndStop = this
            .closeAndStop
            .bind(this);
    }

    handleEnded = () => {
        //console.log('onEnded')
        this.setState({ playing: this.state.loop })
    }

    manageProgress(state, playedSeconds) {
        var _this = this;
        var watchTime = _this.state.watchTime
        if (watchTime == undefined) {
            watchTime = 0
        }
        if (_this.state.questionsBySecond[playedSeconds] != undefined) {
            if (_this.state.questions[_this.state.questionsBySecond[playedSeconds].index].visible == false) {
                var solved = false
                _this.state.uSolvedQuestions.forEach(function (doc) {
                    if (doc.id == _this.state.questions[_this.state.questionsBySecond[playedSeconds].index].id) {
                        solved = true
                    }
                })
                if (!solved) {
                    _this.handleExitFullScreenClick()
                    _this.state.questions[this.state.questionsBySecond[playedSeconds].index].visible = true
                    _this.setState({ playing: false })

                } else {
                    _this.setState({ playing: true })
                }
            }
        }
        
        if (playedSeconds > 0 && playedSeconds % 2 == 0 && watchTime > 0) {
            var procentage = Math.round((watchTime / _this.state.currentVideoDuration) * 100)
            if (procentage >= 98) {
                procentage = 100
            }
            //console.log("Porcentage "+procentage)


            if (_this.state.myVideos[_this.state.currentVideoId] != undefined && _this.state.myVideos[_this.state.currentVideoId] != null) {
                if (_this.state.myVideos[_this.state.currentVideoId].completed == false) {
                    var completed = false
                    var obj = {
                        "watched_time": watchTime,
                        "current_time": playedSeconds,
                        "porcentage": procentage
                    }
                    //console.log("UPDATING OBJ ")
                    //console.log(obj)

                   
                    var watchtimeLimit = _this.props.series.videos_watchtime
                    //console.log("watchtimeLimit: " + watchtimeLimit)
                    if (procentage >= watchtimeLimit) {
                        //console.log("ABOVE WATCH LIMIT!!!!")

                        _this.setState({ readyToGrade: true })
                        var data ={}
                        data.porcentage = 100
                        this.props.updateData(data)

                        completed = true
                        obj["completed"] = true
                        obj["completed_on"] = Moment().format("DD/MM/YYYY'T'hh:mm:ss")

                    }else{
                        //console.log("UNDER WATCH LIMIT")
                    }
                    database
                        .collection("users")
                        .doc(_this.state.user.id)
                        .collection("watched_videos")
                        .doc(_this.state.currentVideoId)
                        .update(obj)
                        .then(() => {
                            //console.log("Document TIME successfully updated!");
                            var myVids = _this.state.myVideos
                            var vid = myVids[_this.state.currentVideoId]
                            vid["watched_time"] = watchTime
                            vid["current_time"] = playedSeconds
                            vid["porcentage"] = procentage
                            vid["completed"] = completed
                            myVids[_this.state.currentVideoId] = vid
                            this.setState({ myVideos: myVids })

                        })
                    // _this.checkSeriesCompletion()
                }else{
                    var watchtimeLimit = _this.props.series.videos_watchtime
                    if (procentage >= watchtimeLimit) {
                        _this.setState({ readyToGrade: true })
                    }else{
                        //console.log("UNDER WATCH LIMIT")
                    }
                }
                /*else {
                    database
                        .collection("users")
                        .doc(_this.state.user.id)
                        .collection("watched_videos")
                        .doc(_this.state.currentVideoId)
                        .update({ watched_time: watchTime, current_time: playedSeconds })
                        .then(() => {
                            //console.log("Document TIME successfully updated!");
                            var myVids = _this.state.myVideos
                            var vid = myVids[_this.state.currentVideoId]
                            vid["watched_time"] = watchTime
                            vid["current_time"] = playedSeconds
                            myVids[_this.state.currentVideoId] = vid
                            this.setState({ myVideos: myVids })

                        })
                }*/
            } else {
                //console.log("BUILDING ITEM")
         
                var item = {
                    "started_on": Moment().format("DD/MM/YYYY'T'hh:mm:ss"),
                    "porcentage": procentage,
                    "series_id": _this.state.seriesId,
                    "evaluation_id": _this.state.evId,
                    "completed": false,
                    "order": _this.state.currentVideo.order,
                    "watched_time": watchTime,
                    "current_time": playedSeconds,
                    "name": _this.state.currentVideo.title
                }
                //console.log("SENDING ITEM")
                //console.log(item)
                var ref = database
                    .collection("users")
                    .doc(_this.state.user.id)
                    .collection("watched_videos")
                    .doc(_this.state.currentVideoId);
                ref
                    .set(item)
                    .then(() => {
                       //console.log("Document TIME successfully CREATED!");
                        var myVids = _this.state.myVideos
                        myVids[_this.state.currentVideoId] = item
                        this.setState({ myVideos: myVids })
                    });
            }
        }

        //console.log("Watchtime: " + watchTime + " Played:" + playedSeconds)
        _this.setState({
            watchTime: (watchTime + 1),
            currentVideoTime: playedSeconds
        });

        //console.log('onProgress', state.playedSeconds)
        // We only want to update time slider if we are not currently seeking

        if (!this.state.seeking) {
            this.setState(state)
        }

    }


    handleExitFullScreenClick() {
        document.webkitExitFullscreen()
    }

    handleProgress = state => {
        var _this = this;


        var playedSeconds = Math.ceil(state.playedSeconds)
        //console.log("playback: " + playedSeconds)
        //console.log("LIMIT: " + _this.state.nextLimit)
        if (_this.state.solvedQuestionsLoaded == true) {
            if (_this.state.nextLimit > 0) {
                if (playedSeconds > _this.state.nextLimit && playedSeconds + 2 > _this.state.lastSecond) {
                    _this.setNextLimit()
                    //console.log("REGRESANDO...")
                    _this.player.seekTo(_this.state.nextLimit, 'seconds')
                    setTimeout(() => {
                        _this.setState({ playing: false })
                    }, 300);
               


                } else {
                    //console.log("ManageProgresss")
                    _this.manageProgress(state, playedSeconds)
                }
            } else {
                //console.log("MAX LIMIT -1...")
                //console.log("ManageProgresss")
                _this.manageProgress(state, playedSeconds)
            }
        } else {
            //console.log("NOT LOADED")
        }
        var por = Math.round((playedSeconds / _this.state.currentVideoDuration) * 100)
        if (por >= 98) {
            por = 100
        }
        _this.setState({ porcentage: por })
        _this.setState({ lastSecond: playedSeconds })
    }

    handleDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({ currentVideoDuration: duration })
        //console.log(this.state.currentVideo.set_by_system)
        if (this.state.currentVideo.set_by_system == undefined || this.state.currentVideo.set_by_system == false) {
            database
                .collection("videos")
                .doc(this.state.currentVideoId)
                .update({
                    "set_by_system": true,
                    "duration_by_system": Math.round(duration)
                })
                .then(() => {
                    //console.log("Document duration successfully updated!");
                });

        } else {

            //console.log("Duration already set")
        }
        var marks = {}
        marks[100] = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0Final"
        marks[0] = "Inicio\xa0\xa0"
        this.state.questions.forEach(function (doc, i) {
            var mark = Math.ceil(((doc.data().show_in_second / duration) * 100))
            marks[mark] = "?"
        });
        this.setState({ marks: marks })
        //console.log("QUESTIONS SET")
    }

    onAddAnswer(answerDoc) {
        //console.log('ADDING NEW ANSER');
        this
            .state
            .uSolvedQuestions
            .push(answerDoc);
        //console.log(this.state.uSolvedQuestions);
        var decimalPorcentage = ((this.state.uSolvedQuestions.length / this.state.questions.length) * 100)
        var porcentage = Math.floor(decimalPorcentage)
        //console.log("PORCENTAGE: " + porcentage)
            var data = {}
            data.porcentage = porcentage
            this.props.updateData(data)
        if (porcentage > 95) {
            this.setState({ gradeButtonVisible: true })
            porcentage = 100
        }
        if (this.state.currentSolvedEvaluation.status != 'En curso') {
            var ref = database.doc(this.state.solvedEvPath);
            ref.update({
                status: 'En curso',
                porcentage: porcentage + ''
            });
            this.state.currentSolvedEvaluation.status = 'En curso';
            this.state.currentSolvedEvaluation.porcentage = porcentage + '';

            //console.log('ONCOURSE update');
        } else {
            var ref = database.doc(this.state.solvedEvPath);
            ref.update({
                porcentage: porcentage + ''
            });
            this.state.currentSolvedEvaluation.porcentage = porcentage + '';
        }




    }

    onUpdateAnswer(answerDoc, index) {
        //console.log('Updating NEW ANSER');
        this.state.uSolvedQuestions[index] = answerDoc
        //console.log(this.state.uSolvedQuestions);
        var decimalPorcentage = ((this.state.uSolvedQuestions.length / this.state.questions.length) * 100)
        var porcentage = Math.floor(decimalPorcentage)
        if (porcentage > 100) {
            porcentage = 100
        }
        if (this.state.currentSolvedEvaluation.status != 'En curso') {
            var ref = database.doc(this.state.solvedEvPath);
            ref.update({
                status: 'En curso',
                porcentage: porcentage + ''
            });
            this.state.currentSolvedEvaluation.status = 'En curso';
            this.state.currentSolvedEvaluation.porcentage = porcentage + '';

            //console.log('ONCOURSE update');
        } else {
            var ref = database.doc(this.state.solvedEvPath);
            ref.update({
                porcentage: porcentage + ''
            });
            this.state.currentSolvedEvaluation.porcentage = porcentage + '';
        }
    }

    showModal = () => {
        this.setState({ visible: true });
    };
    handleOk = () => {
        this.setState({ confirmLoading: true });
        setTimeout(() => {
            this.setState({ visible: false, confirmLoading: false });
        }, 300);
    };
    handleCancel = (question) => {
        //console.log('Clicked cancel button');
        if (question.visible) {
            question.visible = false;
        }
    };

    setRedirect = () => {
        this.setState({ redirect: true });
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/signin" />;
        }
    };

    renderRedirectHome = () => {
        if (this.state.redirectHome) {
            return <Redirect to={'series/' + this.state.seriesId + '/evaluations'} />;
        }
    };

    getAllCertificateData(obi) {
        var _this = this;

        database
            .collection('users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id + '/solved_evaluations')
            .get()
            .then(function (querySnapshot) {
                var filter = querySnapshot
                    .docs
                    .filter((evId) => evId.data().status == 'Aprobado');
                if (filter.length >= obi.docs.length) {
                    _this.setState({ certificateWinner: true });
                    _this.getCertificateReady();
                } else {
                    _this.setState({ approved: true });
                }

            })
            .catch(function (error) {
                //console.log("Error getting documents: ", error);
            });

    }
    checkIfCertificateOrMessage() {
        //console.log('Checking certificate');
        var _this = this;
        database
            .collection("evaluations")
            .where('series', '==', _this.state.seriesId)
            .get()
            .then(function (querySnapshot) {
                //console.log("GOT ALL DATA")
                _this.getAllCertificateData(querySnapshot)
            })
            .catch(function (error) {
                //console.log("Error getting documents: ", error);
            });

    }

    gradeEvaluation = () => {
        var _this = this;
        //console.log("\nGRADE EVALUATION")

        if (!_this.state.graded) 
        {
            //console.log("NOT GRADED")
       
            if (_this.state.questions.length > _this.state.uSolvedQuestions.length) {
                //console.log("FIRST CASE")

                _this.setState({ danger: true, loading: true });
                setTimeout(() => {
                    this.setState({ danger: false, loading: false });
                }, 2000);
            } else {
                //console.log("SECOND CASE")
                //console.log(_this.state.solvedQuestionsPath)

                database
                    .collection(_this.state.solvedQuestionsPath)
                    .get()
                    .then(function (snapshot) {
                        //console.log("GOTTEN SOLVED QUESTIONS")

                        var hasAllAnswers = true
                        var correctAnswers = 0;
                        var wrongAnswers = 0;
                        _this
                            .state
                            .questions
                            .forEach(function (doc) {
                                var answersForQuestion = snapshot
                                    .docs
                                    .filter(answers => answers.data().question == doc.id)
                                if (!answersForQuestion) {
                                    hasAllAnswers = false
                                } else {
                                    if (answersForQuestion.length > 1) {
                                        answersForQuestion
                                            .sort(function (a, b) {
                                                return Moment(a.data().timestamp).isAfter(Moment(b.data().timestamp));
                                            });

                                    }

                                    if (answersForQuestion[0].data().was_correct == 'true') {
                                        correctAnswers++;
                                    } else {
                                        wrongAnswers++;
                                    }

                                }
                            });
                        if (!hasAllAnswers) {
                            //console.log("NOT ANSWERED")

                            _this.setState({ gradeMessage: 'Debes responder todas las preguntas para poder recibir una calificación' });
                            _this.setState({ failure: true });
                        } else {
                            //console.log("ANSWERED")

                            var grade = ((_this.state.questions.length - wrongAnswers) / _this.state.questions.length) * 100;
                            grade = Math.floor(grade)
                            if (grade > 100) {
                                grade = 100
                            }
                            //console.log('GRADE');
                            _this.setState({
                                grade: grade + ""
                            });
                            //console.log(grade);
                            //console.log(_this.state.solvedEvPath);
                            if (_this.state.serie.data().evaluation_procentage > grade) {
                                database
                                    .doc(_this.state.solvedEvPath)
                                    .update({
                                        correct_answers: correctAnswers + '',
                                        wrong_answers: wrongAnswers + '',
                                        timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                                        grade: grade,
                                        porcentage: '100',
                                        evaluation_id: _this.state.evaluation.id,
                                        status: 'Reprobado',
                                        evaluation_name: _this
                                            .state
                                            .evaluation
                                            .data()
                                            .title
                                    });
                                _this.setState({
                                    gradeMessage: 'Lo sentimos, no has llegado al número mínimo de aciertos para aprobar la evaluación, Inténtelo de nuevo.\nCalificación: ' + grade + '%'
                                });
                                _this.setState({ failure: true });
                                

                                var tries = Number(_this.state.currentSolvedEvaluation.attempt_number);
                                var limit = Number(_this.state.serie.data().number_of_tries);
                                // //console.log('CURRENT TRY' +
                                // _this.state.currentSolvedEvaluation.attempt_number); //console.log('LIMIT:' +
                                // _this.state.serie.data().number_of_tries);
                                if (limit > tries) {
                                    var obj = {
                                        "watched_time": 0,
                                        "current_time": 0,
                                        "porcentage": 0,
                                        "completed" : false,
                                        "completed_on" : ""
                                    }
                            
                                    var data = {}
                                    data.grade = 0
                                    data.porcentage = 0
                                    data.status =  'Por iniciar'
                                    data.attempt = tries + 1
                                    _this.props.updateData(data)
                                    //console.log("RESETTING HOME")

                                    database
                                        .collection("users")
                                        .doc(_this.state.user.id)
                                        .collection("watched_videos")
                                        .doc(_this.state.currentVideoId)
                                        .update(obj)
                                        .then(() => {
                                            //console.log("UPDATED WATCHTIMEE IMPORTANT")
                                         }).catch(function (error) {
                                            //console.log('ERROR UPDATING ATTEMPT : '); 
                                            //console.log(error);
                                        });
                                    var attempt = Number(tries) + 1;
                                    //console.log(_this.state.path);
                                    var finalPAth = _this.state.path;
                                    database
                                        .collection(finalPAth + '/solved_evaluations')
                                        .add({
                                            correct_answers: '0',
                                            wrong_answers: '0',
                                            timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                                            grade: '0',
                                            evaluation_id: _this.state.evaluation.id,
                                            porcentage: '0',
                                            status: 'Por iniciar',
                                            attempt_number: attempt + '',
                                            evaluation_name: _this
                                                .state
                                                .evaluation
                                                .data()
                                                .title
                                        })
                                        .then(function () {
                                            //console.log("UPDATED TRIE IMPORTANT")
                                            // //console.log('NEW ATTEMPT ADDED '
                                            // +(_this.state.currentSolvedEvaluation.attempt_number + 1));
                                        })
                                        .catch(function (error) {
                                            //console.log('ERROR INSERTING ATTEMPT : '); 
                                            //console.log(error);
                                        });
                                } else {
                                    var data = {}
                                    data.grade = grade
                                    data.porcentage = 100
                                    data.status =  'Reprobado'
                                    data.attempt = limit
                                    _this.props.updateData(data)
                                    _this.setState({ maxTries: true });
                                }
                            } else {
                                //console.log("SUCESS EVENT")
                                database
                                    .doc(_this.state.solvedEvPath)
                                    .update({
                                        correct_answers: correctAnswers + '',
                                        wrong_answers: wrongAnswers + '',
                                        timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                                        grade: grade,
                                        porcentage: '100',
                                        evaluation_id: _this.state.evaluation.id,
                                        status: 'Aprobado',
                                        evaluation_name: _this
                                            .state
                                            .evaluation
                                            .data()
                                            .title
                                    })
                                    .then(function () {
                                        var data = {}
                                        data.grade = grade
                                        data.porcentage = 100
                                        data.status =  'Aprobado'
                                        data.attempt = limit
                                        _this.props.updateData(data)
                                        _this.checkIfCertificateOrMessage();
                                    })
                                    .catch(function (er) {
                                        //console.log('ERROR INSERTING ATTEMPT : '); 
                                        //console.log(er);
                                    });
                            }

                            if (_this)
                                _this.setState({ disabledButtons: true, gradedButton: 'Regresar', graded: true });
                        }

                    });

            }
        } else {
            //console.log("REDIRECT HOME")
            _this.setState({ redirectHome: true });
        }
    };

    getCertificateReady() {
        var _this = this;

        var addMessage = functions.httpsCallable('createCertificate');
        var n = new Date().getTime();
        var folio = (n + '').substr((n + '').length - 5);
        Moment().locale('es', localization)

        var dateMessage = 'Ciudad de México, ' + Moment(new Date())
            .locale('es')
            .format('D [de] MMMM [del]  YYYY');
        addMessage({
            seriesId: _this.state.serie.id,
            userId: _this.state.user.id,
            folio: folio,
            date: dateMessage,
            formalDate: Moment(n).format('DD/MM/YYYY HH:mm:ss'),
            accessed_series: 'users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id
        })
            .then(function (result) {
                // Read result of the Cloud Function.
                var sanitizedMessage = result.data.text;
            })
            .catch(function (error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                // ...
            });
    }

    getQuestionOptions(question, index) {
        var _this = this;
        database
            .collection('options')
            .where('question', '==', question.id)
            .get()
            .then(function (querySnapshot) {
                //console.log('OPTIONS'); //console.log(querySnapshot);
                if (querySnapshot.docs.length > 0) {
                    var sorted = querySnapshot
                        .docs
                        .sort(function (a, b) {
                            if (a.data().order < b.data().order) {
                                return -1;
                            }
                            if (a.data().order > b.data().order) {
                                return 1;
                            }
                            return 0;
                        });
                    question.options = sorted;
                    question.visible = true;
                    _this.setState({ currentOptions: sorted, currentTitle: question.title });
                    _this.showModal();
                }
            });
    }

    getQuestionsInformation() {
        var _this = this;
        database
            .collection('questions')
            .where('evaluation', '==', _this.state.evId)
            .get()
            .then(function (querySnapshot) {

                var sorted = querySnapshot
                    .docs
                    .sort(function (a, b) {
                        if (Number(a.data().show_in_second) < Number(b.data().show_in_second)) {
                            return -1;
                        }
                        if (Number(a.data().show_in_second) > Number(b.data().show_in_second)) {
                            return 1;
                        }
                        return 0;
                    });
                var bySecond = {}

                sorted.forEach(function (doc, i) {
                    doc.visible = false
                    doc.index = i
                    bySecond[doc.data().show_in_second] = doc
                });
                //console.log("QUESTIONS SET")
                _this.setState({ questions: sorted, questionsBySecond: bySecond });
            });
    }

    getEvaluationInformation(id) {
        var _this = this;
        //console.log(_this.state.evId)
        database
            .doc('evaluations/' + _this.state.evId)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    if (doc.data().series == id) {
                        _this.setState({ evaluation: doc });
                        _this.getQuestionsInformation();
                        _this.getSolvedSeriesInformation();

                    } else {
                        //console.log('EVALUATION DOES NOT EXIST');
                    }
                } else {
                    //console.log('EVALUATION DOES NOT EXIST');
                }
            });
    }

    getSeriesInformation() {
        var _this = this;
        database
            .doc('series/' + _this.state.seriesId)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    _this.setState({ serie: doc });
                    _this.getEvaluationInformation(doc.id);

                } else {
                    //console.log('SERIES DOES NOT EXIST');
                }
            });
    }

    getVideoUserInformation(videoDoc) {
        //console.log("DOC ID")
        //console.log(videoDoc.id)
        var _this = this;
        var path = "/users/" + _this.state.user.id + "/watched_videos/" + videoDoc.id
        database
            .doc(path)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    var myVideos = {};
                    myVideos[doc.id] = doc.data()
                    _this.setState({
                        myVideos: myVideos,
                        watchedVideoDoc: doc,
                        currentVideo: videoDoc.data(),
                        currentVideoId: videoDoc.id,
                        currentVideoTime: doc.data().current_time,
                        watchTime: doc.data().watched_time
                    });
                    //console.log("CURRENT VIDEO TIME "+doc.data().current_time)
                } else {
                    //console.log("NEVER WATCHED THE VIDEO")
                    _this.setState({
                        currentVideo: videoDoc.data(),
                        currentVideoId: videoDoc.id
                    });
                }
                _this.setNextLimit()

            });
    }

    setNextLimit() {
        //console.log("SETTING LIMIT")
        var _this = this;
        var nexlimit = -1;
        var nextLimitSet = false;
        var i = 0;
        _this.setState({ nextLimit: -1 })

        if (_this.state.solvedQuestionsLoaded) {

            while (!nextLimitSet && i < _this.state.questions.length) {
                var solved = false
                _this.state.uSolvedQuestions.forEach(function (question, j) {
                    if (_this.state.questions[i].id == _this.state.uSolvedQuestions[j].id) {
                        _this.state.questions[i].solved = true
                        solved = true
                    }
                });
                if (!solved) {
                    nextLimitSet = true
                } else {
                    i++
                }
            }

            if (nextLimitSet) {
                var lim = _this.state.questions[i].data().show_in_second
                //console.log("SETNEXT LIMIT " + lim + " SET")
                _this.setState({ nextLimit: lim })
                if (_this.state.currentVideoTime > 0) {
                    if (_this.state.currentVideoTime <= _this.state.nextLimit) {
                        setTimeout(() => {
                            _this.player.seekTo(_this.state.currentVideoTime+2, "seconds")
                        }, 200);

                    } else {
                        setTimeout(() => {
                            _this.player.seekTo(_this.state.nextLimit - 5, "seconds")
                        }, 200);
                    }
                }

            } else {
                //console.log("NO NEXT LIMIT")

                _this.setState({ nextLimit: -1 })
                if (_this.state.questions.length > 0) {
                    _this.setState({ gradeButtonVisible: true })
                }

                if (_this.state.currentVideoTime > 0) {
                    _this.player.seekTo(_this.state.currentVideoTime+2, "seconds")
                }
            }
        }

    }

    getVideosInformation() {
        var _this = this;
        //console.log("VIDEO DATA")
        //console.log(_this.state.evaluation.data().video)
        database
            .doc('videos/' + _this.state.evaluation.data().video)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    _this.getVideoUserInformation(doc)
                } else {
                    _this.setState({ gradeMessage: 'No hay ningún video asignado para esta evaluación' });
                    _this.setState({ failure: true });
                }
            });
    }

    getSolvedSeriesInformation() {
        var _this = this;
        var path = '/users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id + '/solved_evaluations';
        var ref = database.collection(path);

        ref
            .where('evaluation_id', '==', this.state.evId)
            .get()
            .then(function (snapshot) {
                if (snapshot.docs.length == 0) {
                    _this.writeNewAttempt(path, 1);
                } else {
                    var approved = snapshot
                        .docs
                        .filter((word) => word.data().status == 'Aprobado');
                    var failed = snapshot
                        .docs
                        .filter((word) => word.data().status == 'Reprobado');
                    var limit = Number(_this.state.serie.data().number_of_tries);

                    if (approved.length == 0) {
                        if (failed.length < limit) {
                            var filter = snapshot
                                .docs
                                .filter((word) => word.data().status == 'Por iniciar' || word.data().status == 'En curso');
                            if (filter.length > 0) {

                                var solvedEv = filter[0];
                                var partialPath = path + '/' + solvedEv.id;
                                var completePath = partialPath + '/solved_questions';
                                _this.setState({
                                    path: '/users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id,
                                    currentSolvedEvaluation: solvedEv.data(),
                                    solvedQuestionsPath: completePath,
                                    solvedEvPath: partialPath
                                });
                                database
                                    .collection(completePath)
                                    .get()
                                    .then(function (solvedQuestions) {

                                        if (solvedQuestions.docs.length != 0) {

                                            _this.setState({ solvedQuestions: solvedQuestions.docs, uSolvedQuestions: solvedQuestions.docs, solvedQuestionsLoaded: true });
                                            _this.getVideosInformation()
                                        } else {
                                            _this.setState({ solvedQuestions: [], uSolvedQuestions: [], solvedQuestionsLoaded: true });
                                            _this.getVideosInformation()
                                        }
                                    });
                            } else {
                                _this.writeNewAttempt(path, failed.length + 1);
                            }
                        } else {
                            _this.getVideosInformation()
                            _this.setState({ maxTries: true });
                        }
                    } else {
                        _this.getVideosInformation()
                        _this.setState({ approved: true, grade: approved[0].data().grade });
                    }
                }
            });
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    writeNewAttempt(path, attempt) {
        //console.log('NO ATTEMP, WRITING NEW ONE');
        var _this = this;
        if(_this.state.myVideos[_this.state.currentVideoId] != undefined && _this.state.myVideos[_this.state.currentVideoId] != null){
        var obj = {
            "watched_time": 0,
            "current_time": 0,
            "porcentage": 0,
            "completed" : false,
            "completed_on" : ""
        }

        database
            .collection("users")
            .doc(_this.state.user.id)
            .collection("watched_videos")
            .doc(_this.state.currentVideoId)
            .update(obj)
            .then(() => {
             })
            }

        database
            .collection(path)
            .add({
                correct_answers: '0',
                wrong_answers: '0',
                status: 'Por iniciar',
                evaluation_name: _this
                    .state
                    .evaluation
                    .data()
                    .title,
                attempt_number: attempt + '',
                evaluation_id: this.state.evId,
                grade: '0',
                porcentage: '0',
                timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
            })
            .then(function (doc) {
                var path = '/users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id + '/solved_evaluations';

                database
                    .doc(path + '/' + doc.id)
                    .get()
                    .then(function (item) {
                        //console.log('Document successfully written!'); //console.log(item);

                        var partialPath = path + '/' + item.id;
                        var completePath = partialPath + '/solved_questions';
                        //console.log(path);
                        _this.setState({
                            currentSolvedEvaluation: item.data(),
                            path: path,
                            solvedQuestions: [],
                            solvedQuestionsLoaded: true,
                            uSolvedQuestions: [],
                            solvedEvPath: partialPath,
                            solvedQuestionsPath: completePath,
                            solvedEvPath: partialPath
                        });
                        _this.getVideosInformation()
                    });
            })
            .catch(function (error) {
                //console.error('Error writing document: ', error);
            });
    }

    getUserAccessibleSeries(userSnapshot) {
        var _this = this;
        var doc = userSnapshot.docs[0];
        _this.setState({ user: doc });
        database
            .collection('/users/' + doc.id + '/accesed_series')
            .where('series_id', '==', _this.state.seriesId)
            .get()
            .then(function (accesedSeries) {
                if (accesedSeries.docs.length == 0) {
                    _this.setState({ denied: true });
                } else {
                    _this.setState({ accesedSerie: accesedSeries.docs[0] });
                    _this.getSeriesInformation();
                }
            });
    }
    ref = player => {
        this.player = player
    }
    getUserInformation(user) {
        var _this = this;
        database
            .collection('users')
            .where('email', '==', user.email)
            .get()
            .then(function (querySnapshot) {
                _this.getUserAccessibleSeries(querySnapshot);
            })
            .catch(function (error) {
                //console.log('Error gettin0g document:', error);
            });
    }


    componentDidMount() {
        //const { seriesId, evId } = this.props; //console.log(this.props.match.params)
        var _this = this;
        this.setState({ seriesId: _this.props.seriesId, evId: _this.props.evId });
        auth.onAuthStateChanged((user) => {
            if (user) {
                _this.getUserInformation(user);
            } else {
                this.setRedirect();
            }
        });
    }

    closeAndStop() {
        this.setState({ playing: false })
        setTimeout(() => {
            this.props.onModalClosing()
        }, 1000);
    }

    success() {
        Modal.success({ title: 'This is a success message', content: 'some messages...some messages...' });
    }
    handleVisibleChange = popOverVisibility => {
        if (this.state.readyToGrade == false) {
            this.setState({ popOverVisibility });
        }
    };
    hidePop = () => {
        this.setState({
            popOverVisibility: false,
        });
    };
    hideAllQuestionModals(index) {
        this.setNextLimit()
        this.state.questions[index].visible = false
        setTimeout(() => {
            this.setState({ playing: true })
        }, 2500);

    }

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px'
        };
        return (
            <div>

                <div>


                    {this.state.solvedQuestionsLoaded && this.state.questions.length > 0 && this.state.solvedQuestionsPath != ''
                        ? (this.state.questions.map((question, index) => (

                            <VideoQuestion
                                setNextLimit={() => this.setNextLimit()}
                                hideAllQuestionModals={(index) => this.hideAllQuestionModals(index)}
                                disabledButtons={this.state.disabledButtons}
                                key={index}
                                index={index}
                                danger={this.state.danger}
                                onAddAnswer={this.onAddAnswer}
                                onUpdateAnswer={this.onUpdateAnswer}
                                user={this.state.user}
                                question={question}
                                index={index}
                                path={this.state.solvedQuestionsPath}
                                solvedQuestion={this
                                    .state
                                    .solvedQuestions
                                    .find(function (item) { return item.data().question == question.id; })} />


                        )))
                        : null}


                    <ReactPlayer
                        width='100%'
                        height='100%'
                        ref={this.ref}
                        playing={this.state.playing}
                        //onReady={() => this.player.seekTo(this.state.currentVideoTime)}
                        height='450px'
                        onProgress={this.handleProgress}
                        onEnded={this.handleEnded}
                        onDuration={this.handleDuration}
                        url={this.state.currentVideo.link}
                        controls="controls" />
                </div>
                <br></br>
                <Row>
                    <Col lg={3} md={3} sm={24} xs={24}>
                        <Button id="closeModalButton" type="dashed" onClick={() => this.closeAndStop()}>Cerrar</Button></Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <Slider min={0} max={100} value={this.state.porcentage} disabled={true} marks={this.state.marks} defaultValue={1} />
                    </Col>
                    <Col lg={5} md={5} sm={24} xs={24}>


                        {(this.state.gradeButtonVisible) ? <Popover
                            content={<a onClick={this.hidePop}>Cerrar</a>}
                            title="Espera al final del video para calificar tu evaluación"
                            trigger="click"

                            visible={this.state.popOverVisibility}
                            onVisibleChange={this.handleVisibleChange}
                        >
                            {(this.state.readyToGrade) ? <Button type="primary" onClick={() => this.gradeEvaluation()}>Calificar evaluación</Button> : <Button >Calificar evaluación</Button>}
                        </Popover> : null}</Col></Row>

                <SweetAlert
                    show={this.state.success}
                    success
                    title={this.state.gradeMessage}
                    onConfirm={() => {
                        this.setState({ success: false });
                    }} />
                <SweetAlert
                    show={this.state.failure}
                    error
                    title={this.state.gradeMessage}
                    onConfirm={() => {
                        this.setState({ failure: false });
                    }} />
                <SweetAlert
                    show={this.state.maxTries}
                    error
                    title="Lo sentimos, has llegado al máximo número de intentos para esta evaluación"
                    onConfirm={() => {
                        this.setState({ maxTries: false });
                    }} />
                <SweetAlert
                    show={this.state.denied}
                    error
                    title="No tiene acceso al contenido de esta serie, por favor solicite un código de acceso"
                    onConfirm={() => {
                        this.setState({ denied: false });
                    }} />
                <SweetAlert
                    show={this.state.certificateWinner}
                    success
                    title="Felicidades, ha aprobado, su certificado será enviada a su correo electrónico en algunos minutos."
                                        onConfirm={() => {
                        this.setState({ certificateWinner: false });
                    }} />
                <SweetAlert
                    show={this.state.approved}
                    success
                    title={"Has aprobado esta evaluación.\nCalificación:" + this.state.grade + '%'}
                    onConfirm={() => {
                        this.setState({ approved: false });
                    }} />

            </div>
        );
    }
}
export default VideoEvaluation;

/*
const Icon = () => {
  return (<Card className="gx-card" title="Icon">
      <Button type="primary" size="large" icon="search"/>
      <Button type="primary" size="large" icon="search">Search</Button>
      <Button shape="circle" icon="search"/>
      <Button icon="search">Search</Button>
      <br/>
      <Button shape="circle" icon="search"/>
      <Button icon="search">Search</Button>
      <Button type="dashed" shape="circle" icon="search"/>
      <Button type="dashed" icon="search">Search</Button>
    </Card>
  );
};*/
