import React, { Component } from 'react';
import { Col, Row, Card } from 'antd';
import ProductItem from "components/eCommerce/ProductItem";
import { auth, database } from "../../../firebase/firebase";

class BookContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      urlId: 0,
      bookId: 0,
      series: {},
      book:{},
    };
    //this.handleChange=this.handleChange.bind(this);
  }

  getUserInformation(user) {
    //console.log("ALL USER INFO CALLED");
    var _this = this;
    database
      .collection("users")
      .where("email", "==", user.email)
      .get()
      .then(function(querySnapshot) {
        //console.log("ID: " + _this.state.urlId);
        querySnapshot.forEach(function(doc) {
          _this.setState({ user: doc.data() });
          doc.ref
            .collection("accesed_series")
            .where("series_id", "==", _this.state.urlId)
            .get()
            .then(function(snapshot) {
              var refArray = [];
              snapshot.forEach(function(series) {
                refArray.push(series.data());
              });
              //console.log(refArray);
              if (refArray.length == 0) {
                //console.log("NO ACCESS TO THIS BOOK");
              } else {
                database
                  .doc("series/" + _this.state.urlId)
                  .get()
                  .then(function(doc) {
                    if (doc.exists) {
                      var series = doc.data();
                      //console.log(series);
                      _this.setState({ series: series });
                        database
                          .collection("books")
                          .doc(_this.state.bookId)
                          .get()
                          .then(function(snapshot) {
                            //console.log("BOOKS")
                            
                              _this.setState({ book: snapshot.data() });
                              
                            
                          });
                    } else {
                      //console.log("Series does not exist");
                    }
                  });
              }
            });
        });
      })
      .catch(function(error) {
        //console.log("Error gettin0g document:", error);
      });
  }

  componentDidMount() {
    const { urlId, bookId } = this.props.match.params;
    var _this = this;

    this.setState({ urlId: urlId, bookId: bookId});
    auth.onAuthStateChanged(user => {
      if (user) {
        _this.getUserInformation(user);
      }
    });
  }

  render() {
    return ( 
      <div>
				<Row>
					<Col key="ss" xl={16} lg={16} md={16} sm={24} xs={24}>
						<Card className={`gx-card-metrics`}>
							<h1 className="gx-product-title sponsor">{this.state.book.content_title}</h1>
							<h4 className='gray-text'>{this.state.book.content_description !== 0 && this.state.book.content_description !== ''?this.state.book.content_description :""}</h4>
						</Card>
					</Col>
					<Col key="1a" xl={8} lg={8} md={8} sm={24} xs={24}>
						<Card className={`gx-card-metrics`}>
							<img src={this.state.series.sponsor_logo} />
						</Card>
					</Col>
     
        <div className='card-like-div'>
         {( this.state.book.content !== "") ?<div className="fontFutura" dangerouslySetInnerHTML={{ __html: this.state.book.content }}></div>:null}
        </div>
      </Row>
      </div>
    );
  }
}

export default BookContent;
