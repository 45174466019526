import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';
import { version } from "util/config";

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from "./Topbar/index";
import HorizontalDefault from "./Topbar/HorizontalDefault/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			console.log(values);
			if (!err) {
				this.props.showAuthLoader();
				this.props.userSignIn(values);
			}
		}); 
	};

	handleButtonClick(e) {
		message.info('Click on left button.');
		//console.log('click left button', e);
	}

	handleMenuClick(e) {
		message.info('Click on menu item.');
		//console.log('click', e);
	}

	componentDidUpdate() {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			auth.onAuthStateChanged((user) => {
				if (user) {
					this.props.history.push('/');
				} else {
					localStorage.removeItem('user_id');
				}
			});
		} else {
			//console.log('AUTH USER IS NULL');
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		return (

			<div className="gx-app-login-wrap">

				<div className="gx-app-login-wrap" id="login-cont">

					<div className="gx-app-login-container">

						<div className="gx-app-login-main-content">

							<div className="gx-app-login-content">

								<Row><Col span="6"></Col><Col span="12">
									<img alt="example" src={require('assets/images/logo_login.png')} /> 	<br></br>	<br></br>	<br></br>
								</Col><Col span="3"></Col>
								<p id="login-message"><span className="dark-blue-text heavy-text">PiSA Farmacéutica</span> le da la bienvenida a nuestra plataforma de Educación Médica Continua.<br></br><span className="heavy-text green-text">Le invitamos a iniciar sesión</span></p>
								</Row>
								<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
									<FormItem>
										{getFieldDecorator('email', {
											rules: [
												{
													required: true,
													type: 'email',
													message: 'Por favor introduce un correo electrónico válido'
												}
											]
										})(<Input placeholder="Correo electrónico" className="big-input"/>)}
									</FormItem>
									<FormItem>
										{getFieldDecorator('password', {
											rules: [{ required: true, message: 'Por favor introduce tu contraseña' }]
										})(<Input className="big-input" type="password" placeholder="Contraseña" />)}
									</FormItem>
									<Link className="gx-login-form-forgot" to="/reset-password">Olvidé mi contraseña</Link>
									<br></br>
									<br></br>

									<FormItem>
										<Button id="sesion" type="primary" className="gx-mb-0" htmlType="submit">
											<IntlMessages id="app.userAuth.signIn" />
										</Button>
										
										<Link to="/signup" className="gx-login-form-forgot">
										<IntlMessages id="app.userAuth.or" /> <IntlMessages id="app.userAuth.signUp" />
										</Link>
									</FormItem>
								</Form>


							</div>
							<Row className='bottom-links-login links-login'>
								<div className='bottom-links-center'>
								<a className="gx-login-bottom-link" href="https://www.doctualiza.com.mx/soporte" target='_blank'>Soporte</a>
								<span>&nbsp;&nbsp;&nbsp;</span>
								<a className="gx-login-bottom-link" href="">·</a>
								<span>&nbsp;&nbsp;&nbsp;</span>
								<a className="gx-login-bottom-link" href="https://www.doctualiza.com.mx/formulariocontacto" target='_blank'>Enviar correo electrónico</a>
								<span>&nbsp;&nbsp;&nbsp;</span>
								<a className="gx-login-bottom-link" href="">·</a>
								<span>&nbsp;&nbsp;&nbsp;</span>
								<a className="gx-login-bottom-link" href="https://www.doctualiza.com.mx/aviso" target='_blank'>Aviso de Privacidad</a>
						
								</div>
								</Row>
							<span id="version-code-login">
							{version}</span>
							{loader ? (
								<div className="gx-loader-view">
									<CircularProgress />
								</div>
							) : null}
							{showMessage ? message.error(alertMessage.toString()) : null}
						</div>
					
					</div>

				</div>
				<div className='corner-logo'><img id="pisa-logo" alt="example" src={require('assets/images/pisa_logo.png')} /> </div>
				<div class="vl"></div>
				<hr class="hl"></hr>

			</div>
			
		);
	}
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedNormalLoginForm);
