import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyApWQjBhaOxfc9DMLkdBADoGRFPj5T7ooo",
  authDomain: "doctualiza.firebaseapp.com",
  projectId: "doctualiza",
  storageBucket: "doctualiza.appspot.com",
  messagingSenderId: "1081225931636",
  appId: "1:1081225931636:web:906180ad050b2843c95a21",
  measurementId: "G-NBK7XXE4RQ"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.firestore();

export {
  database,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
